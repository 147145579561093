import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LoginContext } from './context/LoginContext';

const PrivateRoute = function () {
  const { state } = useContext(LoginContext);
  const auth = localStorage.getItem('user'); // determine if authorized, from context or however you're doing it

  return state.isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
