import React, { useEffect, useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useSnackbar } from 'notistack';
import LocalCafeTwoToneIcon from '@mui/icons-material/LocalCafeTwoTone';
import Map from './Map';
import AddMachineModal from '../../AddMachineModal';
import { fetchMachines, addMachine } from '../../api/Machine';
import { LoginContext } from '../../context/LoginContext';

const drawerWidth = 240;

export const mainListItems = (
  <div>
    <ListItem button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Orders" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />m 
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>

  </div>
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

const DashboardContent = function () {
  const [machineLocation, setmachineLocation] = useState([]);
  const [userLocation, setuserLocation] = useState();
  const [ismapLoading, setismapLoading] = useState(true);
  const { state } = React.useContext(LoginContext);
  const [longitude, setLongitude] = useState(43.204457129024625);
  const [latitude, setLatitude] = useState(27.91753198694475);

  const { enqueueSnackbar } = useSnackbar();

  const snackMessage = () => {
    if (state.username === process.env.REACT_APP_NAME) {
      return enqueueSnackbar(
        process.env.REACT_APP_MESSAGE,
        { variant: 'default', autoHideDuration: 2000 },
      );
    }
    return null;
  };

  const titleUser = () => {
    if (state.username === process.env.REACT_APP_NAME) {
      return process.env.REACT_APP_NICKNAME;
    }
    return state.username;
  };

  useEffect(() => {
    fetchMachines(longitude, latitude).then((res) => {
      if (res.data.success) {
        setmachineLocation(res.data.machines);
        setismapLoading(false);
      }
    }).catch((error) => {
      setismapLoading(false);
      alert(error);
    });
    return () => {};
  }, [longitude, latitude]);

  const onAddMachine = async (arr) => {
    const res = await addMachine(state.accessToken, arr);

    if (res.data.success) {
      setmachineLocation((machineLocation) => [...machineLocation, res.data.machine]);
      enqueueSnackbar(res.data.message, { variant: 'success' });
    } else {
      enqueueSnackbar('Something is wrong!,Check if machine exists on your location', {
        variant: 'error',
      });
    }
  };

  function success(pos) {
    const crd = pos.coords;
    const arr = {
      coordinates: [crd.latitude, crd.longitude],
    };
    onAddMachine(arr);
  }

  function error(err) { alert(err.message); }

  const options = {
    enableHighAccuracy: true,
    timeout: 9000,
    maximumAge: 0,
  };

  const getUserLocation = async () => {
    await navigator.geolocation.getCurrentPosition(success, error, options);
  };

  const handleMapPress = (latlng) => {
    setLatitude(latlng.latitude);
    setLongitude(latlng.longitude);
  };

  useEffect(() => {
    enqueueSnackbar('Click on the map to see your location', { variant: 'info' });
    setTimeout(() => {
      snackMessage();
    }, 2000);
    return () => { };
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute">
          {' '}
          {/*  open={open}  */}
          <Toolbar
            sx={{
              //     pr: '24px', // keep right padding when drawer closed
            }}
          >
            {/* <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton> */}
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
              style={{ color: 'white' }}
            >
              CoffeeTime -
              {' '}
              {titleUser()}
            </Typography>

            <AddMachineModal
              getUserLocation={() => getUserLocation()}
              onAddMachine={(coordinates) => onAddMachine(coordinates)}
            />
          </Toolbar>
        </AppBar>
        {/*  <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{secondaryListItems}</List>
        </Drawer> */}
        <Box
          component="main"
          sx={{
            flexGrow: 1, overflow: 'auto', height: '100vh',
          }}
   /*        sx={{
            backgroundColor: (theme) => (theme.palette.mode === 'green'// 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900]),
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',

          }} */
        >

          <Toolbar />
          {ismapLoading ? (
            <div style={{
              textAlign: 'center',
              marginTop: '100px',
            }}
            >
              <CircularProgress color="warning" />
            </div>
          )
            : (
              <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>

                <Map
                  longitude={longitude}
                  latitude={latitude}
                  machines={machineLocation}
                  userLocation={userLocation}
                  username={state.username}
                  handleMapPress={(latlng) => handleMapPress(latlng)}
                />
              </Container>
            )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
const Dashboard = function () {
  return <DashboardContent />;
};
export default Dashboard;
