/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import {
  MapContainer, TileLayer, Marker, Tooltip, useMapEvents, Popup,
} from 'react-leaflet';

const blackIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const violetIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
/*
const LocationMarker = function () {
  const [position, setPosition] = useState(null);
  const map = useMapEvents({
    click() {
      map.locate();
    },
    locationfound(e) {
      console.log(e.latlng);
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  return position === null ? null : (

    <Marker icon={blackIcon} position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
}; */

const Map = function ({
  machines, userlocation, username, longitude,
  latitude, handleMapPress,
}) {
  const [position, setPosition] = useState(null);

  const LocationMarker = function () {
    const map = useMapEvents({
      click() {
        map.locate();
      },
      locationfound(e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
        handleMapPress(e.latlng);
      },
    });

    return position === null ? null : (

      <Marker icon={blackIcon} position={position}>
        <Popup>You are here</Popup>
      </Marker>
    );
  };

  return (

    <MapContainer
      center={[longitude, latitude]}
      zoom={15}
    //  scrollWheelZoom={false}
      preferCanvas
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {machines?.map((machine) => (
        <Marker
          icon={violetIcon}
          title={machine._id}
      /*     eventHandlers={{
            click: (e) => {
              // console.log(machine._id);
            },
          }} */
        //  onPress={() => )}
          key={machine._id}
          position={[machine.location.coordinates[0],
            machine.location.coordinates[1],
          ]}
        >
          <Tooltip permanent>
            {machine.user.username ? machine.user.username : username}
            's Machine
          </Tooltip>
        </Marker>
      ))}
      <LocationMarker />

    </MapContainer>

  );
};

export default Map;
