import axios from 'axios';

// const BaseURL = process.env.REACT_APP_BASE_URL;
const BaseURL = 'https://coffeetime.link/api/machines';

export const fetchMachines = (lat, long) => axios.get(`${BaseURL}/mylocation/${lat}/${long}`);
export const addMachine = (token, machine) => axios.post(BaseURL, machine, {
  headers: {
    Authorization: token,
  },
});
