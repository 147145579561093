/* eslint-disable class-methods-use-this */
import axios from 'axios';

// const BaseURL = process.env.REACT_APP_BASE_URL;

const BaseURL = 'https://coffeetime.link/api';

class AuthService {
  login(username, password) {
    return axios({
      method: 'post',
      url: `${BaseURL}/login`,
      header: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      data: {
        username,
        password,
      },
    });
  }
/*
  async authHeader() {
    const user = await AsyncStorage.getItem('user');
    const parsed = JSON.parse(user);
    if (user) {
      return { Authorization: parsed.token };
    }
    return {};
  } */
}

export default new AuthService();
