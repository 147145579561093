import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LoginContext } from './context/LoginContext';

const PublicRoute = function () {
  const { state } = useContext(LoginContext);

  return state.isLoggedIn ? <Navigate to="/dashboard" /> : <Outlet />;
};

export default PublicRoute;
