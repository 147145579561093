const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILED = 'LOGIN_FAILED';
const LOGOUT = 'LOGOUT';

export const loginState = {
  isLoggingIn: false,
  isLoggedIn: false,
  accessToken: '',
  authMessage: '',
  username: '',
};

export const loginReducer = (state = loginState, action) => {
  switch (action.type) {
    case LOGIN_IN_PROGRESS:
      return {
        ...state,
        isLoggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
        accessToken: action.payload.token,
        authMessage: 'Logged Succesfully',
        username: action.payload.username,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: false,
        authMessage: action.payload.message,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
