import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddMachineModal = function ({ onAddMachine, getUserLocation }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();

  const handleShareMyLocation = () => {
    getUserLocation();
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const arr = {
      coordinates: [longitude, latitude],
    };
    onAddMachine(arr);
    setOpen(false);
  };

  return (
    <div style={{ display: 'flex' }}>
      <IconButton color="inherit" onClick={handleOpen}>
        <AddCircleIcon color="white" />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} style={{ textAlign: 'center' }}>
            <Button variant="outlined" onClick={() => handleShareMyLocation()}> Add current location </Button>
            <Typography
              align="center"
              component="h2"
              variant="subtitle"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Or
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TextField
                margin="normal"
                type="text"
                required
                id="longitude"
                label="longitude"
                name="longitude"
                onChange={(e) => setLongitude(parseFloat(e.target.value))}
              />
              <TextField
                margin="normal"
                type="text"
                required
                id="latitude"
                label="latitude"
                name="latitude"
                onChange={(e) => setLatitude(parseFloat(e.target.value))}
              />
              <Box>
                <IconButton
                  onClick={() => setOpen(false)}
                  style={{ marginRight: '10px' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  <CancelIcon color="error" />
                </IconButton>
                <IconButton
                  type="submit"
                  style={{ marginLeft: '10px' }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  <CheckCircleIcon color="success" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default AddMachineModal;
