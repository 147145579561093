import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import PrivateRoute from './PrivateRoute';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import PublicRoute from './PublicRoute';
import { LoginProvider } from './context/LoginContext';
import './App.css';

const App = function () {
  return (

    <LoginProvider dense>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Login />} />
            </Route>

          </Routes>
        </Router>
      </SnackbarProvider>
    </LoginProvider>
  );
};

export default App;
