/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react';
import { loginReducer, loginState } from '../reducers/login';
import AuthService from '../api/Auth';

const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILED = 'LOGIN_FAILED';
const LOGOUT = 'LOGOUT';

const LoginContext = createContext(loginState);

const LoginProvider = function ({ children }) {
  const [state, dispatch] = useReducer(loginReducer, loginState);

  const loginSuccess = (data) => {
    dispatch({ type: LOGIN_SUCCESS, payload: data });
  };

  const loginFailed = (data) => {
    dispatch({ type: LOGIN_FAILED, payload: data });
  };

  const login = (username, password) => {
    dispatch({
      type: LOGIN_IN_PROGRESS,
      call: AuthService.login(username, password).then((res) => {
        if (res.data.success) {
          loginSuccess(res.data);
        } else {
          loginFailed(res.data);
        }
      }),
    });
  };

  const logout = async () => {
    dispatch({ type: LOGOUT });
  };

  return (
    <LoginContext.Provider value={{ state, login, logout }}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginProvider, LoginContext };
